import { Provider } from '@angular/core';
import { PRODUCTS_ROUTE_PATH } from 'products-data';
import { provideRoutes } from 'routing-util';
import { ProductsFeatureRoutesConfig, defaultProductsFeatureRoutesConfig } from './ProductsFeatureRoutesConfig';

export function provideProductsFeatureRoutes(config?: Partial<ProductsFeatureRoutesConfig>): Provider {
  const useConfig = { ...defaultProductsFeatureRoutesConfig, ...config };
  return [
    {
      provide: PRODUCTS_ROUTE_PATH,
      useValue: useConfig.path,
    },
    provideRoutes([
      {
        path: useConfig.path,
        loadChildren: () => import('./routes/routes').then((m) => m.routes),
      },
    ]),
  ];
}
