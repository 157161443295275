<ideal-loading-container class="" xloadingx="!contentOn">
  @if (contentOn) {
    <div>
      TTW joy!
      <br />
      fdsf
    </div>
  }
</ideal-loading-container>

<button (click)="contentOn = !contentOn">toggle content</button>
{{ contentOn ? "on" : "off" }}

<!-- <ideal-link-logo-progress-bar [value]="someValue"></ideal-link-logo-progress-bar>

<input #input type="number" (input)="someValue = +input.value" /> -->

<!-- <ideal-infinite-grid-layout [data]="list" layoutStrategy="maxItemFit">
  <mat-card test-card *gridCell="let cell; let i = index">
    <mat-card-header>
      <mat-card-title>{{ cell }}</mat-card-title>
      <mat-card-subtitle>index: {{ i | number }}</mat-card-subtitle>
    </mat-card-header>
    <div mat-card-image>
      <ideal-image [src]="'https://picsum.photos/400/275?random=' + i" fill fit="cover" />
    </div>
    <mat-card-content> Content </mat-card-content>
  </mat-card>
</ideal-infinite-grid-layout> -->
<!-- <ideal-image src="https://picsum.photos/6600/1240" fill fit="contain" style="width: 50%" /> -->
<!-- <ideal-image src="https://sdfgjhdsgdsfjhdsfjldsfhjdsf/dfjhsdfjkhgsdkfj/fdsf.jpg" fill fit="contain"></ideal-image> -->
<!-- <button (click)="toggleOpen = !toggleOpen">LL</button>
<ideal-loading-placeholder style="width: 200px; height: 200px" [loading]="toggleOpen"></ideal-loading-placeholder>
<h2>goodbye</h2> -->

<!-- <input #input type="number" (input)="someValue = +input.value" [value]="someValue" min="1" max="5" />
<ideal-carousel-layout [data]="list" [layoutStrategy]="'maxItemsPerPage'">
  <mat-card test-card *carouselItem="let cell; let i = index">
    <mat-card-header>
      <mat-card-title>{{ cell }}</mat-card-title>
      <mat-card-subtitle>index: {{ i | number }}</mat-card-subtitle>
    </mat-card-header>
    <div mat-card-image>
      <!- - <ideal-image [src]="'https://picsum.photos/400/275?random=' + i" fill fit="cover" /> -- >
    </div>
    <mat-card-content> Content </mat-card-content>
  </mat-card>
</ideal-carousel-layout> -->

<!-- <ideal-carousel [data]="list" [layoutStrategy]="'maxItemsPerPage'" [pageDuration]="15000" [controlPosition]="'overlay-top'">
  <mat-card test-card *carouselItem="let cell; let i = index">
    <mat-card-header>
      <mat-card-title>{{ cell }}</mat-card-title>
      <mat-card-subtitle>index: {{ i | number }}</mat-card-subtitle>
    </mat-card-header>
    <div mat-card-image>

    </div>
    <mat-card-content> Content </mat-card-content>
  </mat-card>
</ideal-carousel> -->
