import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingContainerComponent, LoadingPhDirective } from 'common-ui';

@Component({
  selector: 'ideal-components-page',
  standalone: true,
  imports: [
    LoadingContainerComponent,
    LoadingPhDirective,
    // FormsModule,
    // ReactiveFormsModule,
    // MatFormFieldModule,
    // MatButtonModule,
    // MatListModule,
    // MatCardModule,
  ],
  templateUrl: './components.component.html',
  styleUrl: './components.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentsPageComponent {
  protected contentOn = false;
  // protected store = inject(ProductStore);
  // protected a = this.store.get('EFAFAC706');

  // protected toggleOpen: boolean = false;
  // protected toggleInline: boolean = false;

  // protected someValue = 5;
  // protected someSignal = signal<string>('Some Signal');
  // protected formElmRef = viewChild.required<string, ElementRef<HTMLFormElement>>('formElm', { read: ElementRef });

  // protected list = Array.from({ length: 20 }).map((_, i) => `Item #${i + 1}`);

  // protected onScrollEnd(): void {
  //   console.log('Reached end of list');
  // }

  // protected readonly firstNameControl = new TextFormControl(null, {
  //   required: true,
  //   minLength: 3,
  //   maxLength: 30,
  //   charactersAllowed: /[a-zA-Z0-9]/,
  //   characterDenied: /[0-9]/,
  //   additionalValidators: [
  //     (c) => {
  //       return c.value?.includes('x') ? { x: true } : null;
  //     },
  //   ],
  // });

  // protected readonly lastNameControl = new TextFormControl('TTT', {
  //   required: true,
  //   maxLength: 30,
  //   charactersAllowed: /[a-zA-Z]/,
  //   lengthValidationStrategy: 'error',
  //   characterValidationStrategy: 'error',
  // });

  // protected readonly ageControl = new IntegerFormControl(null, {
  //   required: true,
  //   min: 0,
  //   max: 150,
  //   stepCtrl: 5,
  //   stepShift: 10,
  // });

  // protected readonly passwordControl = new PasswordFormControl('', {
  //   required: true,
  //   minLength: 8,
  //   nonNullable: true,
  // });

  // protected readonly passwordConfirmControl = new PasswordFormControl('', {
  //   nonNullable: true,
  //   matchesControl: 'password',
  // });

  // protected readonly notesControl = new TextFormControl(null);

  // protected readonly form = new FormGroup({
  //   firstName: this.firstNameControl,
  //   lastName: this.lastNameControl,
  //   age: this.ageControl,
  //   password: this.passwordControl,
  //   passwordConfirm: this.passwordConfirmControl,
  //   notes: this.notesControl,
  // });

  // protected onSubmit(evt: any): void {
  //   this.form.markAllAsTouched();
  //   if (this.form.invalid) {
  //     const elm = this.formElmRef().nativeElement;
  //     const firstInvalidField = elm.querySelector('input.ng-invalid, textarea.ng-invalid, select.ng-invalid');
  //     if (firstInvalidField && 'focus' in firstInvalidField && typeof firstInvalidField.focus === 'function') {
  //       firstInvalidField.focus();
  //     }
  //     return;
  //   }
  // }
  // protected onReset(evt: any): void {
  //   console.log('Form reset', this.form.value);
  // }
}
